<template>
  <div v-if="themeGroups?.length" class="py-1">
    <div class="page-container">
      <div class="theme-groups" :class="{ 'theme-groups--carousel': props.carouselMode }">
        <Carousel v-if="props.carouselMode" :breakpoints="getThemeGroupsBreakpoints()" :itemsToShow="1" :itemsToScroll="1">
          <Slide v-for="parentGroup in themeGroups.filter(parentGroup => parentGroup.groups?.[0]?.groups?.length).sort((a, b) => b.priority - a.priority)" :key="parentGroup.page.url">
            <div class="theme-groups__item" :style="`background-image: url('${useShopZone().value.staticResourcesRoot}/img/groups/topic_tile/${parentGroup.id}.jpg');`">
              <AtomsTextLink :to="parentGroup.page.url" class="theme-groups__item__name">
                {{ parentGroup.name }}
              </AtomsTextLink>
              <div class="theme-groups__item__groups">
                <template v-for="helperSubGroup in parentGroup.groups">
                  <AtomsTextLink v-for="group in helperSubGroup.groups.sort((a, b) => b.priority - a.priority)" :to="group.page.url">{{ group.name }}</AtomsTextLink>
                </template>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
        <div v-else class="grid gap-4 tablet:grid-cols-2 desktop:grid-cols-4">
          <template v-for="parentGroup in themeGroups.filter(parentGroup => parentGroup.groups?.[0]?.groups?.length).sort((a, b) => b.priority - a.priority)">
            <div class="theme-groups__item" :style="`background-image: url('${useShopZone().value.staticResourcesRoot}/img/groups/topic_tile/${parentGroup.id}.jpg');`">
              <AtomsTextLink :to="parentGroup.page.url" class="theme-groups__item__name">
                {{ parentGroup.name }}
              </AtomsTextLink>
              <div class="theme-groups__item__groups">
                <template v-for="helperSubGroup in parentGroup.groups">
                  <AtomsTextLink v-for="group in helperSubGroup.groups.sort((a, b) => b.priority - a.priority)" :to="group.page.url">{{ group.name }}</AtomsTextLink>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import { Carousel, Slide, Navigation, Pagination } from '~/components/molecules/containers/carousel.js';
import "assets/css/carousel.css";

const props = defineProps({
  carouselMode: Boolean
});

const locale = useLocale();
const appConfig = useAppConfig();

const themeGroups = useState('themeGroups', () => null);

const getThemeGroupsBreakpoints = () => {
  return {
    0: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    768: {
      itemsToShow: 2,
      snapAlign: "start",
    },
    1440: {
      itemsToShow: 4,
      snapAlign: "start",
    },
  };
};

</script>
<style lang="postcss">
.theme-groups {
  @apply -mx-2 my-8;

  &__item {
    @apply relative overflow-hidden flex-1 p-10 text-center bg-black;

    &::before {
      @apply content-[''] absolute left-0 top-0 right-0 bottom-0 bg-cover bg-center transition-all duration-500 ease-in-out z-[1];
      background-image: inherit;
      filter: grayscale(25%) brightness(30%) saturate(100%);
      z-index: 1;
    }

    &::after {
      @apply content-[''] absolute left-0 top-0 right-0 bottom-0 bg-cover bg-center z-[2] transition-all duration-500 ease-in-out;
      background-image: inherit;
      filter: grayscale(25%) brightness(50%) saturate(50%);
      z-index: 1;
    }

    &:hover::after {
      opacity: 0;
    }

    &:hover::before {
      @apply scale-[1.02];
    }

    &>* {
      position: relative;
      z-index: 3;
    }

    &__name {
      @apply block tablet:pt-16 pb-8 text-4xl font-bold text-secondary-500 transition-all hover:no-underline hover:text-neutral-50;

      &::after {
        @apply content-[''] block w-[80px] mx-auto mt-4 border-b-2 border-secondary-500 transition-all;
      }

      &:hover::after {
        @apply border-neutral-50;
      }
    }

    &__groups {
      @apply flex gap-2 flex-wrap max-h-[12.65rem] overflow-hidden;

      &>a {
        @apply flex-1 inline-block p-1 py-2 whitespace-nowrap border border-neutral-100 uppercase text-xs text-neutral-100 transition-all bg-[#FFFFFF10] tablet:px-3 hover:no-underline hover:text-secondary-500 hover:border-secondary-500 hover:bg-[#00000010];
      }
    }
  }

  &--carousel {
    .theme-groups__item {
      @apply mx-2;

      &__groups {
        @apply max-h-[12.65rem] overflow-hidden;
      }
    }
  }
}
</style>